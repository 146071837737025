<template>
  <div class="menuoptions">
    <div
      v-for="option in menuoptions"
      :key="option.id"
      class="mo_item"
      @click="select($event, option)"
      @touchstart="select($event, option)"
    >
      <div class="span">
        {{ option.dN }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuOptions',
  data() {
    return {
      menuoptions: [],
    }
  },
  mounted() {
    if (this.$compliance.PreferenceSettings.valid)
      this.menuoptions = this.$compliance.PreferenceSettings.getMenu()
  },
  methods: {
    update() {
      if (this.$compliance.PreferenceSettings.valid)
        this.menuoptions = this.$compliance.PreferenceSettings.getMenu()
    },
    select(event, option) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      this.$compliance.PreferenceSettings.confirm(option.id)
    },
  },
}
</script>

<style lang="scss">
.menuoptions {
  position: relative;
  margin: 0 auto;
  width: auto;
  max-width: 80%;
  .mo_item {
    background: #fff;
    width: 100%;
    height: 2em;
    margin: 0 0 15px 0;
    text-align: center;
    line-height: 2em;
    font-size: 1.4em;
    border-radius: 6px;
    border: 1px solid #eee;
  }
}
</style>
