// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/logo.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".prefcontainer{background:#fff;padding:10px 0 10px 0;width:100%}.prefcontainer .logo{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-repeat:no-repeat;width:20%;height:40px;max-width:130px;margin:20px auto 0 auto}.prefcontainer .header{width:100%;height:auto;margin:0 auto 0 auto}.prefcontainer .title{text-transform:uppercase;color:#4f5c68;font-size:1.8em;font-weight:800;line-height:1em;text-align:center;width:100%;display:inline-block;margin:10px auto 10px auto}@media screen and (min-width:768px){.prefcontainer .title{font-size:3.2em}}@media screen and (min-width:1024px){.prefcontainer .title{font-size:5em}}.prefcontainer .text{color:#4c5d6a;font-size:1em;text-align:center;padding:0 30px}.prefcontainer .text.noheader{margin:0}.prefcontainer .text span{color:#688194;text-decoration:underline}@media screen and (min-width:768px){.prefcontainer .text{font-size:1.8em}}@media screen and (min-width:1024px){.prefcontainer .text{font-size:2.1em}}", ""]);
// Exports
module.exports = exports;
