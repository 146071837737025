<template>
  <Layout>
    <div class="prefcontainer">
      <Header
        title=""
        :back="true"
        regulation="exit"
      />
      <div class="logo" />
      <h1 class="title">
        Preferences
      </h1>
      <MenuOptions ref="menu" />
    </div>
  </Layout>
</template>

<script>
import MenuOptions from '@components/menu/MenuOptions.vue'
import Header from '@components/Header.vue'

export default {
  metaInfo() {
    return {
      title: 'Outfit7 Compliance module',
      meta: [
        {
          key: 'description',
          name: 'description',
          content: 'Outfit7 Compliance module',
        },
      ],
    }
  },
  components: {
    MenuOptions,
    Header,
  },
  data() {
    return {
      content: {},
    }
  },
  created() {
    this.$compliance.initCollector('preference-settings')
  },
  mounted() {
    this.content = this.$compliance.getLocalisedContent()
    this.$compliance.PreferenceSettings.MenuOptions = this.$refs.menu
  },
}
</script>

<style lang="scss">
.prefcontainer {
  background: #fff;
  padding: 10px 0 10px 0;
  width: 100%;

  .logo {
    background-image: url('../../assets/images/logo.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 20%;
    height: 40px;
    max-width: 130px;
    margin: 20px auto 0 auto;
  }
  .header {
    width: 100%;
    height: auto;
    margin: 0 auto 0 auto;
  }
  .title {
    text-transform: uppercase;
    color: #4f5c68;
    font-size: 1.8em;
    font-weight: 800;
    line-height: 1em;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 10px auto 10px auto;

    @media screen and (min-width: 768px) {
      font-size: 3.2em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 5em;
    }
  }
  .text {
    color: #4c5d6a;
    font-size: 1em;
    text-align: center;
    padding: 0 30px;
    &.noheader {
      margin: 0;
    }
    span {
      color: #688194;
      text-decoration: underline;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.8em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 2.1em;
    }
  }
}
</style>
